<template>
  <BreadCrumb PageTitle="Lista de Categorias"/>
  <CategoryList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CategoryList from "@/components/Ecommerce/CategoryList/CategoryList.vue";

export default defineComponent({
  name: "CategoryListPage",
  components: {
    BreadCrumb,
    CategoryList,
  },
});
</script>