<template>
  <div class="row">
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black">
              CESPT Design Update
            </span>
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative">
              <i class="ph ph-clock"></i> 2d due
            </span>
            <div>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-link-simple"></i> 12
              </span>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-chat-dots"></i> 2
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black">
              Coding Sprint
            </span>
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../../assets/images/user/user4.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative">
              <i class="ph ph-clock"></i> 1d due
            </span>
            <div>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-link-simple"></i> 05
              </span>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-chat-dots"></i> 3
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black">
              Marketing Content Update
            </span>
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative">
              <i class="ph ph-clock"></i> 2d due
            </span>
            <div>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-link-simple"></i> 12
              </span>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-chat-dots"></i> 2
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black">
              Ceve Frontend
            </span>
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative">
              <i class="ph ph-clock"></i> 2d due
            </span>
            <div>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-link-simple"></i> 12
              </span>
              <span class="d-inline-block text-paragraph position-relative">
                <i class="ph ph-chat-dots"></i> 2
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "TimelineCard",
};
</script>