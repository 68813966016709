<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <!-- Botón para agregar una nueva versión de la aplicación móvil -->
      <div class="d-sm-flex align-items-center">
        <a class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#" data-bs-toggle="modal" data-bs-target="#createNewAppVersionModal" @click.prevent="openModal()">
          Agregar Versión de la App
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="filterResults">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" v-model="searchQuery"
            placeholder="Buscar" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th class="text-uppercase fw-medium fs-13 pt-0 ps-0">Versión</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Plataforma</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Fecha de lanzamiento</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Url</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Obligatoria</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Registro de cambios</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <td>{{ item.version }}</td>
              <td>{{ item.platform }}</td>
              <td>{{ formatDate(item.releaseDate) }}</td>
              <td>
                <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.url }}</a>
              </td>
              <td>{{ item.isMandatory ? "Si" : "No" }}</td>
              <td>{{ item.changelog }}</td>
              <td class="action-link">
                <i class="ph ph-pencil action-link cursor-pointer" style="font-size: 24px;" data-bs-toggle="modal"
                  data-bs-target="#createNewAppVersionModal" @click="openModal(item)" />
                <i class="ph ph-trash ms-2 action-link cursor-pointer" style="font-size: 24px;"
                  @click="deleteAppVersion(item.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
      <p class="ms-20 mb-0 text-paragraph">
        Mostrando <span class="fw-bold">{{ paginatedItems?.length }}</span> de
        <span class="fw-bold">{{ items?.length }}</span> Resultados
      </p>
      <nav class="mt-15 mt-md-0">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Modal para agregar o modificar -->
  <CreateNewAppVersionModal :axios="axios" :id="selectedItem?.id" :no-close-on-backdrop="true" :title="title"
    :item="selectedItem" @modal-closed="handleModalClosed" @app-version-saved="getAllApVersions"
    :existing-app-versions="items" />
</template>

<script>
import { computed } from "vue";
import CreateNewAppVersionModal from "./CreateNewAppVersionModal.vue";
import * as StateStore from "@/utils/store";
import Swal from "sweetalert2";

export default {
  name: "AppVersionList",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  components: {
    CreateNewAppVersionModal,
  },
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() =>
        this.selectedItem != null ? "Editar " : "Crear Nueva "
      ),
    };
  },
  computed: {
    filteredItems() {
      // Filtros de búsqueda
      let filtered = this.items.filter((item) => {
        const versionMatch = item.version.toString().toLowerCase().includes(this.searchQuery?.toLowerCase());
        const platformMatch = item.platform?.toLowerCase().includes(this.searchQuery?.toLowerCase());
        const changelogMatch = item.changelog?.toLowerCase().includes(this.searchQuery?.toLowerCase());
        const isMandatoryMatch = this.searchQuery?.toLowerCase() === 'sí' || this.searchQuery?.toLowerCase() === 'si' || this.searchQuery?.toLowerCase() === 'obligatoria' || this.searchQuery?.toLowerCase() === 'obligatorio' ? item.isMandatory : (this.searchQuery?.toLowerCase() === 'no' ? !item.isMandatory : true);
        return versionMatch || platformMatch || changelogMatch || isMandatoryMatch || item.releaseDate.includes(this.searchQuery);
      });

      // Ordenar por nombre
      filtered.sort((a, b) => a.version - b.version);

      return filtered;
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
  },
  methods: {
    filterResults() {
      // Método vacío; la búsqueda se actualiza automáticamente con v-model.
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Ajuste de la zona horaria para evitar desfasajes
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('es-ES', options);
    },
    handleModalClosed() {
      this.selectedItem = null;
    },
    openModal(item) {
      this.selectedItem = item;
      // console.log("selectedItem: ", this.selectedItem);
    },
    async deleteAppVersion(id) {
      // Usando SweetAlert2 para confirmar la eliminación
      const result = await Swal.fire({
        title: "¿Está seguro de eliminar este registro?",
        text: "No podrá revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });
      if (result.isConfirmed) {
        try {
          await StateStore.actions.actionsDeleteAppVersions({
            axios: this.axios,
            id,
          });
          await this.getAllAppVersions(); // Recarga los datos después de la eliminación.
          // Mostrar mensaje de éxito
          Swal.fire({
            title: "Eliminado",
            text: "La versión de la aplicación ha sido eliminada correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } catch (error) {
          console.error("Error al eliminar la versión:", error);
          Swal.fire({
            title: "Error",
            text: "Hubo un problema al eliminar la versión.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllAppVersions() {
      try {
        await StateStore.actions.actionsGetAllAppVersionsList({
          axios: this.axios,
        });

        // Obtener la lista de versiones y decodificar el campo changelog para cada elemento
        this.items = StateStore.getters.getAppVersionsList().map((item) => {
          return {
            ...item,
            changelog: item.changelog ? decodeURIComponent(item.changelog) : "",
          };
        });
      } catch (error) {
        console.error("Error al obtener las versiones:", error);
      }
    },
  },
  created() {
    this.getAllAppVersions();
  },
};
</script>


<style>
.long-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.icon-circle {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ddd;
}
</style>
