<template>
  <BreadCrumb PageTitle="Rutas"/>
  <RouteList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import RouteList from "../../components/Route/RouteList/RouteList.vue";

export default defineComponent({
  name: "RouteListPage",
  components: {
    BreadCrumb,
    RouteList,
  }
});
</script>