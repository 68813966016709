<template>
  <BreadCrumb PageTitle="Tópicos"/>
  <TopicList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import TopicList from "../../components/Topic/TopicList/TopicList.vue";

export default defineComponent({
  name: "TopicListPage",
  components: {
    BreadCrumb,
    TopicList,
  }
});
</script>