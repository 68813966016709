import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Levels {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                  levelRetrieve(id:${id}){
                    id
                    name
                    game{
                      id
                      name
                    }
                    questions{
                      id
                      name
                      point
                      answers{
                        id
                        name
                        value
                        correct
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.levelRetrieve
            ) {
                response.data.data.levelRetrieve.compressed = {
                    questions: response.data.data.levelRetrieve.questions,
                    game: response.data.data.levelRetrieve.game,
                };
            }

            response.data.data.levelRetrieve.game = response.data.data.levelRetrieve?.game?.id

            return response.data.data.levelRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  levelList{
                    id
                    name
                    game{
                      id
                      name
                    }
                    questions{
                      id
                      name
                      point
                      answers{
                        id
                        name
                        value
                        correct
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("levelList: ", response.data.data?.levelList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.levelList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteLevel ($id: ID!){
                      deleteLevel(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteLevel.ok;
    }

    static async create(
        axios: AxiosInstance,
        name: number,
        game: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  createLevel(newLevel:{
                    game:${game}
                    name:${name}
                  }){
                    level{
                      id
                      name
                      game{
                        id
                        name
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createLevel.ok) {
                return response.data.data.createLevel.ok;
            } else if (response.data.data.createLevel.errors) {
                const errores = response.data.data.createLevel.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createLevel.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        name: number,
        game: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateLevel(newLevel:{
                    id:${id}
                    game:${game}
                    name:${name}
                  }){
                    level{
                      id
                      name
                      game{
                        id
                        name
                      }
                    }
                    ok
                    errors{
                      field
                      messages
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateLevel.ok) {
                return response.data.data.updateLevel.ok;
            } else if (response.data.data.updateLevel.errors) {
                const errores = response.data.data.updateLevel.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateLevel.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
