import { AxiosInstance } from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class AppVersions {
  static async retrieve(axios: AxiosInstance, id: number | string | null) {
    const response = await axios.post(
      '/graphql/',
      `query{
              appVersionRetrieve(id:${id}){
                id
                platform
                url
                version
                releaseDate
                isMandatory
                changelog
              }
            }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        return Promise.resolve(null);
      } else if (error.request) {
        return Promise.resolve(null);
      }
    })
    if (response) {
      return response.data.data.appVersionRetrieve;
    }
  }

  static async all(axios: AxiosInstance) {
    const response = await axios?.post(
      "/graphql/",
      `query{
              appVersionList{
                id
                platform
                url
                version
                releaseDate
                isMandatory
                changelog
              }
            }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    );

    return response.data.data?.appVersionList;
  }


  static async delete(axios: AxiosInstance, id: number) {
    const response = await axios.post(
      "/graphql/",
      {
        query: `mutation deleteAppVersion ($id: ID!){
                      deleteAppVersion(id: $id){
                        ok
                      }
                    }`,
        variables: {
          id: id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data.deleteAppVersion.ok;
  }

  static async create(
    axios: AxiosInstance,
    platform: string,
    releaseDate: any,
    url: string,
    version: string,
    isMandatory: boolean,
    changelog: string,
  ) {
    // Convertir la fecha al formato "YYYY-MM-DD", si no hay fecha se envía la fecha actual
    const currentDate = new Date();
    const defaultDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const formattedDate = releaseDate ? new Date(releaseDate).toISOString().split('T')[0] : defaultDate;

    const response = await axios?.post(
      "/graphql/",
      `mutation {
        createAppVersion(newAppversion: {
          platform: ${platform}
          releaseDate: "${formattedDate}"
          url: "${url}"
          version: "${version}"
          isMandatory: ${isMandatory}
          changelog: "${changelog}"
        }) {
          ok
          errors {
            field
            messages
          }
          appversion {
            id
            platform
            url
            version
            releaseDate
            isMandatory
            changelog
          }
        }
      }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        console.error(error.response.data);
        return Promise.resolve(null);
      } else if (error.request) {
        return Promise.resolve(null);
      }
    });

    if (response && response.data && response.data.data) {
      if (response.data.data.createAppVersion?.ok) {
        return response.data.data.createAppVersion.ok;
      } else if (response.data.data.createAppVersion?.errors) {
        const errores = response.data.data.createAppVersion.errors;
        const mensajes: string[] = [];
        for (let i = 0; i < errores?.length; i++) {
          const error = errores[i];
          for (let j = 0; j < error.messages?.length; j++) {
            mensajes.push(error.messages[j]);
          }
        }
        StateStore.actions.actionsSetErrorMessage(mensajes);
        return null;
      } else if (response.data.errors) {
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } else {
      return null;
    }
  }

  static async edit(
    axios: AxiosInstance,
    id: number,
    platform: string,
    releaseDate: any,
    url: string,
    version: string,
    isMandatory: boolean,
    changelog: string,
  ) {
    // Convertir la fecha al formato "YYYY-MM-DD"
    const currentDate = new Date();
    const defaultDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const formattedDate = releaseDate ? new Date(releaseDate).toISOString().split('T')[0] : defaultDate;

    const response = await axios?.post(
      "/graphql/",
      `mutation{
        updateAppVersion(newAppversion:{
          id:${id}
          platform: ${platform}
          releaseDate: "${formattedDate}"
          url: "${url}"
          version: "${version}"
          isMandatory: ${isMandatory}
          changelog: "${changelog}"
        }){
          ok
          errors{
            field
            messages
          }
          appversion {
            id
            platform
            url
            version
            releaseDate
            isMandatory
            changelog
          }
        }
      }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      }
    ).catch(function (error) {
      if (error.response) {
        console.error(error.response.data);
        return Promise.resolve(null);
      } else if (error.request) {
        return Promise.resolve(null);
      }
    });

    if (response && response.data) {
      if (response.data.data.updateAppVersion.ok) {
        return response.data.data.updateAppVersion.ok;
      } else if (response.data.data.updateAppVersion.errors) {
        const errores = response.data.data.updateAppVersion.errors;
        const mensajes: string[] = [];

        for (let i = 0; i < errores?.length; i++) {
          const error = errores[i];
          for (let j = 0; j < error.messages?.length; j++) {
            mensajes.push(error.messages[j]);
          }
        }
        StateStore.actions.actionsSetErrorMessage(mensajes);
        return null;
      } else if (response.data.errors) {
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } else {
      return null;
    }
  }
}
