import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Section {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
              sectionsRetrieve(id:${id}){
                id
                name
                default
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            // if (
            //     response.data.data.sectionsRetrieve
            // ) {
            //     response.data.data.categoryElementosRetrieve.compressed = {
            //         section: response.data.data.sectionsRetrieve.section,
            //     };
            // }
            //
            // response.data.data.sectionsRetrieve.types = response.data.data.sectionsRetrieve?.section?.id

            return response.data.data.sectionsRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              sectionsList{
                id
                name
                default
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("sectionsList: ", response.data.data?.sectionsList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.sectionsList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteUser ($id: ID!){
                      deleteUser(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteUser.ok;
    }

    static async create(
        axios: AxiosInstance,
        password: string,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        roles: number,
        phone: string,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    createUser (
                    newCustomuser: {
                      password: "${password}",
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      roles: ${roles},
                      phone: "${phone}",
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createUser.ok) {
                await StateStore.actions.actionsSetNewIdUser(response.data.data.createUser.customuser.id);
                return response.data.data.createUser.ok;
            } else if (response.data.data.createUser.errors) {
                const errores = response.data.data.createUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createUser.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        roles: number,
        phone: string,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id},
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      roles: ${roles},
                      phone: "${phone}",
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateUser.ok) {
                return response.data.data.updateUser.ok;
            } else if (response.data.data.updateUser.errors) {
                const errores = response.data.data.updateUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateUser.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
