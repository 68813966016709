<template>
  <div class="row">
    <div class="d-sm-flex align-items-center">
      <a
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#createEditElementoGastoModal"
          @click.prevent="handleModalClosed"
      >
        Agregar Elemento de Gasto
        <i class="flaticon-plus position-relative ms-5 fs-12"></i>
      </a>
    </div>
    <div class="col-lg-8 col-xxxl-12">
      <div class="row">
        <div v-for="item in paginatedItems" :key="item.id" class="col-sm-6 col-lg-6 col-xxxl-3">
          <div
            class="card mb-25 border-0 rounded-0 bg-white single-product-box"
          >
            <div class="card-body p-0 letter-spacing">
              <div class="image position-relative">
                <router-link to="/product-details" class="d-block">
                  <img
                    src="../../../assets/images/products/product1.jpg"
                    alt="product"
                  />
                </router-link>
                <button
                  class="fav position-absolute bg-white rounded-circle border-0 p-0 d-inline-block"
                  type="button"
                >
                  <i class="flaticon-heart"></i>
                </button>
              </div>
              <div class="content p-20">
                <h4 class="mb-10 fw-semibold fs-16 fs-lg-18">
                  <a href="#" class="text-decoration-none text-black">
                    Elemento de gasto: {{ item.name }}
                  </a>
                </h4>
                <h5 class="mb-10 fw-semibold fs-16 fs-lg-18">
                  <a href="#" class="text-decoration-none text-black">
                    Categoria: {{ item.categoryElementos.name }}
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ paginatedItems?.length }}</span> out of
          <span class="fw-bold">{{ items?.length }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page">
              <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
            </li>
            <li class="page-item">
              <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <CreateEditElementoGastoModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />
</template>

<script>
import {computed, defineComponent} from "vue";
import * as StateStore from "@/utils/store";
import CreateEditElementoGastoModal
  from "@/components/Ecommerce/ElementosGastos/AddElementoGasto/CreateEditElementoGastoModal.vue";

export default defineComponent({
  name: "ElementosGastosList",
  components: {CreateEditElementoGastoModal},
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() => this.selectedItem === null ? 'Crear Nuevo ' : 'Editar '),
      selectedItem: null,
    }
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.items.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.items?.length / this.itemsPerPage);
    }
  },
  methods: {
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);

    },
    openModal(item) {
      this.selectedItem = item;
      // console.log("selectedItem: ", this.selectedItem);

    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllitems() {
      await StateStore.actions.actionsGetAllElementosList({'axios': this.axios});
      this.items = StateStore.getters.getElementosGastosList();
    }
  },
  async created() {
    await this.getAllitems();
    // console.log("items: ", this.items);
  },
});
</script>