<template>
  <div id="app" class="gradient-background">
    <MainHeader
        v-if="mostrarDashboard"
        :axios="$axios"
    />
    <MainSidebar
        v-if="mostrarDashboard"
        :axios="$axios"
    />
    <div class="main-content d-flex flex-column transition overflow-hidden">
      <router-view />
      <MainFooter v-if="mostrarDashboard" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watchEffect} from "vue";
import {useRoute} from 'vue-router';
import {stateStore} from "@/utils/store";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  setup() {
    const route = useRoute();
    const mostrarDashboard = ref(true);
    watchEffect(() => {
      mostrarDashboard.value = !['/logout', '/login', '/register', '/forgot-password'].includes(route.path);
    });

    const stateStoreInstance = stateStore;
    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
        // console.log("show");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");

        // console.log("hide");
      }
    });

    return {
      mostrarDashboard
    };
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
});
</script>
<style>
#app {
//background: linear-gradient(to right, #975067, #40091A);
}

.sidebar-area {
  background: linear-gradient(to right, #975067, #40091A) !important;
}
.logo {
  background: linear-gradient(to right, #975067, #40091A);
}
.card-head {
//background: linear-gradient(to right, #975067, #40091A);
}

.btn-cancel {
  background-color: red !important;
}

.btn-accept {
  background-color: green !important;
}
</style>