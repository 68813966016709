<template>
  <BreadCrumb PageTitle="Preguntas"/>
  <QuestionsList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import QuestionsList from "../../components/Questions/QuestionsList/QuestionsList.vue";

export default defineComponent({
  name: "QuestionsListPage",
  components: {
    BreadCrumb,
    QuestionsList,
  }
});
</script>