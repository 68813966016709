import { AxiosInstance } from 'axios'; // Importa axios

export default class City {
    // Método para obtener la lista de ciudades
    static async all(axios: AxiosInstance) {
        const response = await axios.post(
            '/graphql/',
            `query{
                cityList {
                    id
                    name
                    state {
                        id
                        name
                    }
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // Manejar error de respuesta
                return Promise.resolve(null);
            } else if (error.request) {
                // Manejar error de solicitud
                return Promise.resolve(null);
            }
        });

        if (response && response.data) {
            return response.data.data.cityList;
        } else {
            return null;
        }
    }

    // Método para obtener una ciudad específica por ID
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                cityRetrieve(id: ${id}) {
                    id
                    name
                    state {
                        id
                        name
                    }
                }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                return Promise.resolve(null);
            } else if (error.request) {
                return Promise.resolve(null);
            }
        });

        if (response && response.data) {
            return response.data.data.cityRetrieve;
        } else {
            return null;
        }
    }
}
