<template>
  <BreadCrumb PageTitle="Jugadores"/>
  <UsersGamerList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import UsersGamerList from "../../components/Users/UsersList/UsersGamerList.vue";

export default defineComponent({
  name: "UsersGamerListPage",
  components: {
    BreadCrumb,
    UsersGamerList,
  }
});
</script>