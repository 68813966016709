import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Questions {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                  questionRetrieve(id:${id}){
                    id
                    name
                    point
                    level{
                      id
                      name
                      game{
                        id
                        name
                      }
                    }
                    answers{
                      id
                      name
                      value
                      correct
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.questionRetrieve
            ) {
                response.data.data.questionRetrieve.compressed = {
                    level: response.data.data.questionRetrieve.level,
                };
            }

            response.data.data.questionRetrieve.level = response.data.data.questionRetrieve?.level?.id

            return response.data.data.questionRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  questionList{
                    id
                    name
                    point
                    level{
                      id
                      name
                      game{
                        id
                        name
                      }
                    }
                    answers{
                      id
                      name
                      value
                      correct
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("questionList: ", response.data.data?.questionList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.questionList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteQuestion ($id: ID!){
                      deleteQuestion(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteQuestion.ok;
    }

    static async create(
        axios: AxiosInstance,
        name: string,
        point: number,
        level: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  createQuestion(newQuestion:{
                    level:${level}
                    name:"${name}"
                    point:${point}    
                  }){
                    ok
                    errors{
                      field
                      messages
                    }
                    question{
                      id
                      name
                      point
                      level{
                        id
                        name
                        game{
                          id
                          name
                        }
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createQuestion.ok) {
                return response.data.data.createQuestion.ok;
            } else if (response.data.data.createQuestion.errors) {
                const errores = response.data.data.createQuestion.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createQuestion.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        name: string,
        point: number,
        level: number,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateQuestion(newQuestion:{
                    id:${id}
                    level:${level}
                    name:"${name}"
                    point:${point}    
                  }){
                    ok
                    errors{
                      field
                      messages
                    }
                    question{
                      id
                      name
                      point
                      level{
                        id
                        name
                        game{
                          id
                          name
                        }
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateQuestion.ok) {
                return response.data.data.updateQuestion.ok;
            } else if (response.data.data.updateQuestion.errors) {
                const errores = response.data.data.updateQuestion.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateQuestion.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
