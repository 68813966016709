<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <!-- Botón para agregar un nuevo tópico -->
      <div class="d-sm-flex align-items-center">
        <a class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#" data-bs-toggle="modal" data-bs-target="#createNewTopicModal" @click.prevent="openModal()">
          Agregar Tópico
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="filterResults">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" v-model="searchQuery"
            placeholder="Buscar" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Imagen
              </th>
              <th class="text-uppercase fw-medium fs-13 pt-0 ps-0">Nombre</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Sección Educativa</th>
              <th class="text-uppercase fw-medium fs-13 pt-0">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="index">
              <!-- Columna del ícono -->
              <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
                <div class="d-flex align-items-center">
                  <div class="icon-wrapper">
                    <img
                      :src="item.image ? `https://${bucketName}.s3.amazonaws.com/Files/${item.image.name}` : require('@/assets/images/favicon.png')"
                      alt="Ícono" class="icon-circle" />
                  </div>
                </div>
              </th>
              <td class="ps-0">{{ item.name }}</td>
              <td>{{ item.educationalSections.name }}</td>
              <td class="action-link">
                <i class="ph ph-pencil action-link cursor-pointer" style="font-size: 24px;" data-bs-toggle="modal"
                  data-bs-target="#createNewTopicModal" @click="openModal(item)" />
                <i class="ph ph-trash ms-2 action-link cursor-pointer" style="font-size: 24px;"
                  @click="deleteTopic(item.id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
      <p class="ms-20 mb-0 text-paragraph">
        Mostrando <span class="fw-bold">{{ paginatedItems?.length }}</span> de
        <span class="fw-bold">{{ items?.length }}</span> Resultados
      </p>
      <nav class="mt-15 mt-md-0">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Modal para agregar o modificar -->
  <CreateNewTopicModal :axios="axios" :id="selectedItem?.id" :no-close-on-backdrop="true" :title="title"
    :item="selectedItem" @modal-closed="handleModalClosed" @topic-saved="getAllArticles" />
</template>

<script>
import { computed } from "vue";
import CreateNewTopicModal from "./CreateNewTopicModal.vue";
import * as StateStore from "@/utils/store";
import Swal from "sweetalert2"; // Importa SweetAlert2

export default {
  name: "TopicList",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  components: {
    CreateNewTopicModal,
  },
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      bucketName: process.env.VUE_APP_BUCKET_NAME,
      title: computed(() =>
        this.selectedItem != null ? "Editar " : "Crear Nuevo "
      ),
    };
  },
  computed: {
    filteredAnswers() {
      return this.items.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          item.educationalSections?.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredAnswers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAnswers?.length / this.itemsPerPage);
    },
  },
  methods: {
    filterResults() {
      // Método vacío; la búsqueda se actualiza automáticamente con v-model.
    },
    handleModalClosed() {
      this.selectedItem = null;
    },
    openModal(item) {
      this.selectedItem = item;
      // console.log("selectedItem: ", this.selectedItem);
    },
    async deleteTopic(id) {
      // Usando SweetAlert2 para confirmar la eliminación
      const result = await Swal.fire({
        title: "¿Está seguro de eliminar este registro?",
        text: "No podrá revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await StateStore.actions.actionsDeleteArticles({
            axios: this.axios,
            id,
          });
          await this.getAllArticles(); // Recarga los datos después de la eliminación.

          // Mostrar mensaje de éxito
          Swal.fire({
            title: "Eliminado",
            text: "El tópico ha sido eliminado correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } catch (error) {
          console.error("Error al eliminar el tópico:", error);
          Swal.fire({
            title: "Error",
            text: "Hubo un problema al eliminar el tópico.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllArticles() {
      try {
        await StateStore.actions.actionsGetAllArticlesList({
          axios: this.axios,
        });
        this.items = StateStore.getters.getArticlesList();
      } catch (error) {
        console.error("Error al obtener los artículos:", error);
      }
    },
  },
  created() {
    this.getAllArticles();
  },
};
</script>


<style>
.long-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.icon-circle {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ddd;
}
</style>
