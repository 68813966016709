import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class Answers {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
            '/graphql/',
            `query{
                  answerRetrieve(id:${id}){
                    id
                    name
                    value
                    correct
                    question{
                      id
                      name
                      point
                      level{
                        id
                        name
                        game{
                          id
                          name
                        }
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.answerRetrieve
            ) {
                response.data.data.answerRetrieve.compressed = {
                    question: response.data.data.answerRetrieve.question,
                };
            }

            response.data.data.answerRetrieve.question = response.data.data.answerRetrieve?.question?.id

            return response.data.data.answerRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  answerList{
                    id
                    name
                    value
                    correct
                    question{
                      id
                      name
                      point
                      level{
                        id
                        name
                        game{
                          id
                          name
                        }
                      }
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        // console.log("answerList: ", response.data.data?.answerList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList?.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.answerList;
    }

    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteAnswer ($id: ID!){
                      deleteAnswer(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteAnswer.ok;
    }

    static async create(
        axios: AxiosInstance,
        name: string,
        value: string,
        question: number,
        correct: boolean,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  createAnswer(newAnswer:{
                    name:"${name}"
                    value:"${value}"
                    question:${question}
                    correct:${correct}
                    
                  }){
                    ok
                    errors{
                      field
                      messages
                    }
                    answer{
                      id
                      name
                      value
                      correct
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createAnswer.ok) {
                return response.data.data.createAnswer.ok;
            } else if (response.data.data.createAnswer.errors) {
                const errores = response.data.data.createAnswer.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.createAnswer.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        name: string,
        value: string,
        question: number,
        correct: boolean,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updateAnswer(newAnswer:{
                    id:${id}
                    name:"${name}"
                    value:"${value}"
                    question:${question}
                    correct:${correct}
                    
                  }){
                    ok
                    errors{
                      field
                      messages
                    }
                    answer{
                      id
                      name
                      value
                      correct
                    }
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                // console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                // console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateAnswer.ok) {
                return response.data.data.updateAnswer.ok;
            } else if (response.data.data.updateAnswer.errors) {
                const errores = response.data.data.updateAnswer.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores?.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages?.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                // console.log("lo que tenemos: ", response.data.data.updateAnswer.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                // console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
}
