<template>
  <BreadCrumb PageTitle="Elementos de Gastos"/>
  <ElementosGastosList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ElementosGastosList from "@/components/Ecommerce/ElementosGastos/ElementosGastosList.vue";

export default defineComponent({
  name: "ElementosGastosPage",
  components: {
    BreadCrumb,
    ElementosGastosList,
  },
});
</script>