<template>
  <div class="row">
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-title-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title fw-bold mb-0 fs-lg-18">
              To do <span class="text-muted">(03)</span>
            </h5>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >CESPT Design Update</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 2d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 12</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 2</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >CESPT Dashboard Code Update</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user12.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-warning text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                A
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-success">Code</span>
            <span class="badge text-outline-warning">Dashboard</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 11</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 3</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Social Media Campaign</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user11.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <a
        href="#"
        class="add-another-task-btn mb-25 letter-spacing d-block w-100 fs-md-15 fw-medium text-center text-decoration-none position-relative bg-white transition text-success"
        data-bs-toggle="modal"
        data-bs-target="#addAnotherTaskModal"
      >
        Add Another Task <i class="ph-bold ph-plus"></i>
      </a>
    </div>
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-title-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title fw-bold mb-0 fs-lg-18">
              In progress <span class="text-muted">(06)</span>
            </h5>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Coding Sprint</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user4.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 1d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 05</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 3</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Developer Hands-off</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user3.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-info text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                C
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-success">Code</span>
            <span class="badge text-outline-warning">Dashboard</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 11</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 3</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Frontend Design Update</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Ceve Client Meeting</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user5.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-danger text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                D
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 2d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 12</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 2</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Ceve CRM Project Estimation</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user12.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-success">Code</span>
            <span class="badge text-outline-warning">Dashboard</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 11</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 3</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Jeel Site Update</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user11.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                A
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <a
        href="#"
        class="add-another-task-btn mb-25 letter-spacing d-block w-100 fs-md-15 fw-medium text-center text-decoration-none position-relative bg-white transition text-info"
        data-bs-toggle="modal"
        data-bs-target="#addAnotherTaskModal"
      >
        Add Another Task <i class="ph-bold ph-plus"></i>
      </a>
    </div>
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-title-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title fw-bold mb-0 fs-lg-18">
              To Review <span class="text-muted">(03)</span>
            </h5>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Marketing Content Update</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 2d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 12</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 2</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Backend Coding push</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user12.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-warning text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                A
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-success">Code</span>
            <span class="badge text-outline-warning">Dashboard</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 11</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 3</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >CESPT LMS Dashboard Update</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user11.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <a
        href="#"
        class="add-another-task-btn mb-25 letter-spacing d-block w-100 fs-md-15 fw-medium text-center text-decoration-none position-relative bg-white transition text-primary"
        data-bs-toggle="modal"
        data-bs-target="#addAnotherTaskModal"
      >
        Add Another Task <i class="ph-bold ph-plus"></i>
      </a>
    </div>
    <div class="col-lg-6 col-xxxl-3">
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-title-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="card-title fw-bold mb-0 fs-lg-18">
              To Complete <span class="text-muted">(05)</span>
            </h5>
            <div class="dropdown">
              <button
                class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="flaticon-dots"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                    View</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-pen lh-1 me-8 position-relative top-1"
                    ></i>
                    Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item d-flex align-items-center"
                    href="javascript:void(0);"
                    ><i
                      class="flaticon-delete lh-1 me-8 position-relative top-1"
                    ></i>
                    Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Ceve Frontend</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user14.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-primary">CESPT</span>
            <span class="badge text-outline-info">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 2d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 12</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 2</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >CESPT E-commerce Site</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user12.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-warning text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                A
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-success">Code</span>
            <span class="badge text-outline-warning">Dashboard</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 11</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 3</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Web Design Team</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user11.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >CESPT Dashboard Design</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user11.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-25 border-0 rounded-0 bg-white kanban-card">
        <div class="card-body p-15 p-sm-20 letter-spacing">
          <div class="mb-10 d-flex align-items-center justify-content-between">
            <span class="d-block fs-md-15 fw-bold text-black"
              >Coding Bootcamp</span
            >
            <div class="users-list d-flex align-items-center">
              <div class="rounded-circle text-center">
                <img
                  src="../../assets/images/user/user11.jpg"
                  class="rounded-circle"
                  alt="user"
                />
              </div>
              <div
                class="rounded-circle bg-primary-emphasis text-center d-flex align-items-center justify-content-center text-white fs-15 fw-semibold"
              >
                P
              </div>
            </div>
          </div>
          <p class="text-paragraph lh-base fs-md-15">
            This project combines all the data and backend information to the
            frontend.
          </p>
          <div class="tags-list">
            <span class="badge text-outline-info">Social</span>
            <span class="badge text-outline-warning">Design</span>
          </div>
          <div
            class="mt-15 info d-flex align-items-center justify-content-between"
          >
            <span class="d-block text-danger position-relative"
              ><i class="ph ph-clock"></i> 3d due</span
            >
            <div>
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-link-simple"></i> 09</span
              >
              <span class="d-inline-block text-paragraph position-relative"
                ><i class="ph ph-chat-dots"></i> 4</span
              >
            </div>
          </div>
        </div>
      </div>
      <a
        href="#"
        class="add-another-task-btn mb-25 letter-spacing d-block w-100 fs-md-15 fw-medium text-center text-decoration-none position-relative bg-white transition text-warning"
        data-bs-toggle="modal"
        data-bs-target="#addAnotherTaskModal"
      >
        Add Another Task <i class="ph-bold ph-plus"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "KanbanContent",
};
</script>